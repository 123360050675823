
import { api } from "@/api/api";
import { ApiGetCustomerDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateArrayNotEmpty, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { newOrderProps } from "./newOrderProps";

export default defineComponent({
  name: "NewOrderDraftCompany",
  components: {},
  props: {
    ...newOrderProps,
  },
  emits: ["draftChange", "validate"],
  setup(props, { emit, refs }) {
    const isInvoiceAndPurchaserEqual = ref(true);
    const companies = ref<ApiGetCustomerDto[]>([]);
    const draftValues = useAutoDeepCloneProp(props, "orderDraft");
    const isLoadingCompanies = ref(false);

    onMounted(async () => {
      isLoadingCompanies.value = true;
      companies.value = (await api.customer.getCustomerOrganizationsAsync()).data;
      isLoadingCompanies.value = false;
    });

    const validate = () => {
      const isValid = !!getValidatableRef(refs.participantForm)?.validate();
      emit("validate", isValid);
    };

    const emitChanges = () => {
      validate();
      if (isInvoiceAndPurchaserEqual.value) {
        draftValues.value.invoiceRecipientUserId = draftValues.value.customerUserId;
      }
      emit("draftChange", draftValues.value);
    };

    return {
      isLoadingCompanies,
      isInvoiceAndPurchaserEqual,
      draftValues,
      validateArrayNotEmpty,
      validateNotEmpty,
      companies,
      emitChanges,
      validate,
    };
  },
});
