var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "participantForm",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": _vm.validate
    }
  }, [_c('v-row', {
    staticClass: "mx-1"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.courseParticipantStatuses,
      "loading": _vm.isLoadingParticipantStatus,
      "label": "Deltakerstatus",
      "dense": ""
    },
    on: {
      "click": _vm.getParticipantStatuses
    },
    model: {
      value: _vm.courseParticipantStatus,
      callback: function callback($$v) {
        _vm.courseParticipantStatus = $$v;
      },
      expression: "courseParticipantStatus"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "dense": "",
      "label": "Ordre opprettet fra før",
      "row": ""
    },
    model: {
      value: _vm.existingOrderOnArticle,
      callback: function callback($$v) {
        _vm.existingOrderOnArticle = $$v;
      },
      expression: "existingOrderOnArticle"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Ja",
      "value": _vm.PreviousOrder.Yes
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Nei",
      "value": _vm.PreviousOrder.No
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Vis alle",
      "value": _vm.PreviousOrder.DisplayAll
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "data-cy": "orderModalParticipantSelect",
      "items": _vm.courseParticipants,
      "item-text": "userFullName",
      "item-value": "userId",
      "label": "Deltaker",
      "rules": _vm.validateArrayNotEmpty,
      "multiple": "",
      "clearable": "",
      "dense": ""
    },
    on: {
      "click": _vm.updateParticipantList,
      "change": _vm.emitChanges
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function fn() {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": _vm.toggleUserIds
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectAllIcon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Velg alle deltakere")])], 1)], 1), _c('v-divider', {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.draftValues.userIds,
      callback: function callback($$v) {
        _vm.$set(_vm.draftValues, "userIds", $$v);
      },
      expression: "draftValues.userIds"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Fakturering ")])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "dense": "",
      "label": "Faktura sendes",
      "row": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.draftValues.details.isCollectiveInvoice,
      callback: function callback($$v) {
        _vm.$set(_vm.draftValues.details, "isCollectiveInvoice", $$v);
      },
      expression: "draftValues.details.isCollectiveInvoice"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Samlet",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Separat",
      "value": false
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }