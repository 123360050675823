var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "participantForm",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": _vm.validate
    }
  }, [_c('v-row', {
    staticClass: "mx-1"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Betaler ")])]), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "label": "Bestiller og fakturamottaker er like",
      "row": ""
    },
    model: {
      value: _vm.isInvoiceAndPurchaserEqual,
      callback: function callback($$v) {
        _vm.isInvoiceAndPurchaserEqual = $$v;
      },
      expression: "isInvoiceAndPurchaserEqual"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Ja",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "nei",
      "value": false
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.isInvoiceAndPurchaserEqual ? 12 : 6
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": _vm.isInvoiceAndPurchaserEqual ? 'Bestiller og fakturamottaker' : 'Bestiller',
      "items": _vm.companies,
      "item-text": "customer.name",
      "item-value": "customer.customerId",
      "loading": _vm.isLoadingCompanies,
      "rules": _vm.validateNotEmpty,
      "hide-details": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.draftValues.customerUserId,
      callback: function callback($$v) {
        _vm.$set(_vm.draftValues, "customerUserId", $$v);
      },
      expression: "draftValues.customerUserId"
    }
  })], 1), !_vm.isInvoiceAndPurchaserEqual ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Fakturamottaker",
      "items": _vm.companies,
      "item-text": "customer.name",
      "item-value": "customer.customerId",
      "loading": _vm.isLoadingCompanies,
      "rules": _vm.validateNotEmpty,
      "hide-details": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.draftValues.invoiceRecipientUserId,
      callback: function callback($$v) {
        _vm.$set(_vm.draftValues, "invoiceRecipientUserId", $$v);
      },
      expression: "draftValues.invoiceRecipientUserId"
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }