var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.singleOrder ? _c('BaseLayout', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "py-1"
        }, [_vm._v("mdi-format-list-bulleted")]), _vm._v(" Ordre ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.isEditing = !_vm.isEditing;
            }
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? "Avslutt redigering" : "Rediger"))])];
      },
      proxy: true
    }], null, false, 43283545)
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('SingleOrderDetailValues', {
    staticClass: "px-4",
    attrs: {
      "isEdit": _vm.isEditing,
      "order": _vm.singleOrder,
      "customerPersons": _vm.customerPersons,
      "customerOrganizations": _vm.customerOrganizations
    },
    on: {
      "cancelEdit": function cancelEdit($event) {
        _vm.isEditing = false;
      },
      "updateOrderValues": _vm.updateOrderValues
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.singleOrder ? _c('CourseSingleOrderTable', {
    attrs: {
      "orderValuesWithUUID": _vm.orderValuesWithUUID,
      "orderLinesWithUUID": _vm.orderLinesWithUUID,
      "articleNos": _vm.articleNos,
      "isEditable": true
    },
    on: {
      "updateOrderLines": _vm.updateOrderLines
    }
  }) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Avbryt")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.isEditing
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Lagre endringer")])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }