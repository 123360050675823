var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Nytt ordreutkast")];
      },
      proxy: true
    }])
  }, [_c('NewOrderStepper', {
    attrs: {
      "courseId": _vm.courseId,
      "course": _vm.course,
      "courseParticipantStudents": _vm.courseParticipantStudents
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }