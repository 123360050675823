
import { api } from "@/api/api";
import { ApiGetCourseDto, ApiGetCourseParticipantDto, ApiGetCurriculumDto, ApiGetOrderDto } from "@/api/generated/Api";
import CourseOrderTable from "@/components/course/economy/CourseOrderTable.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { CourseOrderStatus } from "@/shared/enums/courseOrderStatusEnum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { loadAllCourseData } from "@/shared/helpers/courseHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseEconomyPage",
  components: { CourseOrderTable, BaseLayout },
  setup() {
    const route = useRoute();
    const courseId = +route.params.id;
    const course: Ref<ApiGetCourseDto | undefined> = ref();
    const courseParticipantStudents: Ref<ApiGetCourseParticipantDto[]> = ref([]);
    const studyplan: Ref<ApiGetCurriculumDto | undefined> = ref();
    const contentReady = ref(false);
    const courseOrders = ref<ApiGetOrderDto[]>([]);
    const courseRefundOrders = ref<ApiGetOrderDto[]>([]);
    const currentTab = ref("order");

    const headerValues = ref<{ title: string; value?: string | number | null }[]>([]);

    const loadOrders = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const courseOrderList = (await api.course.getOrdersAsync(+route.params.id)).data;

        const courseOrderListFiltered = courseOrderList.map((order) => ({
          ...order,
          isSelectable: order.status?.id === CourseOrderStatus.DraftValue,
        }));

        courseOrders.value = courseOrderListFiltered.filter((x) => !x.courseParticipantExpenseId);

        courseRefundOrders.value = courseOrderListFiltered.filter((x) => x.courseParticipantExpenseId);
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        ({
          course: course.value,
          courseParticipantStudents: courseParticipantStudents.value,
          studyplan: studyplan.value,
        } = await loadAllCourseData(courseId));
        setHeaderValues();
        contentReady.value = true;
      });
      loadOrders();
    });

    const setHeaderValues = () => {
      headerValues.value = [
        { title: "Kurs", value: course.value?.courseName },
        { title: "Kurs pris", value: course.value?.economy?.price },
        { title: "Deltakere", value: courseParticipantStudents.value.length },
        { title: "Total saldo", value: "0" },
      ];
    };

    const navigateBack = () => useNavigateBack(SingleCourseRouteNames.CourseDashboard);

    return {
      contentReady,
      headerValues,
      course,
      navigateBack,
      courseParticipantStudents,
      courseOrders,
      currentTab,
      loadOrders,
      courseRefundOrders,
    };
  },
});
