
import { api } from "@/api/api";
import {
  ApiCreateDraftDto,
  ApiGenerateOrganizationOrderTemplateDto,
  ApiGenerateParticipantOrderTemplateDto,
  ApiGetCourseParticipantDtoType,
  ApiGetKursAdminCourseDto,
  ApiOrderTemplateDto,
} from "@/api/generated/Api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { OrderApplicationStatus } from "@/shared/enums/OrderApplicationStatus.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NewOrderDraftExtended } from "@/shared/interfaces/NewOrderDraftExtended";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import NewOrderDraftCompanyVue from "./NewOrderDraftCompany.vue";
import NewOrderDraftFormVue from "./NewOrderDraftForm.vue";
import NewOrderDraftParticipantVue from "./NewOrderDraftParticipant.vue";
import NewOrderOverviewVue from "./NewOrderOverview.vue";

export default defineComponent({
  name: "NewOrderStepper",
  props: {
    courseId: {
      type: Number,
      required: true,
    },
    courseParticipantStudents: {
      type: Array as PropType<ApiGetCourseParticipantDtoType[]>,
      required: true,
    },
    course: {
      type: Object as PropType<ApiGetKursAdminCourseDto>,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore<StoreState>();

    const orderDraft = ref<NewOrderDraftExtended>(getOrderDraftTemplateInitialValues(+route.params.id));
    const updateOrderDraft = (draft: NewOrderDraftExtended) => {
      orderDraft.value = draft;
    };

    const order = ref<ApiOrderTemplateDto[]>();
    const updateOrder = (updateOrder: ApiOrderTemplateDto[]) => {
      order.value = updateOrder;
    };

    const isOrderDraftValid = ref({
      articleSelected: false,
      participantSelected: false,
      paymentSelected: false,
    });

    const applicationStatus = computed(() => {
      if (!props.course) {
        return undefined;
      }
      return OrderApplicationStatus.None;
    });

    const generateParticipantOrder = async (orderTemplate: ApiGenerateParticipantOrderTemplateDto[]) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        order.value = (await api.order.getTemplatesParticipant(orderTemplate)).data;
        if (orderDraft.value.details?.isCollectiveInvoice) {
          const mapped: ApiOrderTemplateDto[] = Object.values(
            order.value.reduce((acc: any, cur: any) => {
              const lookupKey = `${cur.invoiceRecipient?.customerNo ?? ""}-${cur.participant?.customerNo ?? ""}`;

              return {
                ...acc,
                [lookupKey]: {
                  ...cur,
                  orderLines: [...(acc[lookupKey]?.orderLines ?? []), ...cur.orderLines],
                },
              };
            }, {})
          );

          order.value = mapped;
        }
        openNotification(store, NotificationItemType.Success, "Ordreutkast opprettet");
        isOrderDraftValid.value.paymentSelected = true;
        nextStep();
      });
    };

    const generateCompanyOrder = async (orderDraft: ApiGenerateOrganizationOrderTemplateDto) => {
      order.value = [(await api.order.getTemplatesOrganization(orderDraft)).data];

      openNotification(store, NotificationItemType.Success, "Ordreutkast opprettet");
      isOrderDraftValid.value.paymentSelected = true;
      nextStep();
    };

    const createOrder = async (orderBody: ApiCreateDraftDto) => {
      try {
        const orderId = (await api.order.createDraft(orderBody)).data.id;
        openNotification(store, NotificationItemType.Success, `Ordre opprettet med id ${orderId}`);
      } catch (e) {
        return orderBody;
      }
    };

    const isApplicationStatus = (...statusList: OrderApplicationStatus[]) =>
      applicationStatus.value && statusList.includes(applicationStatus.value);

    const steps = computed((): NewOrderStep[] =>
      [
        {
          title: "Ordrevalg",
          locked: !isApplicationStatus(OrderApplicationStatus.None),
          valid: isOrderDraftValid.value.articleSelected,
          content: [
            {
              component: NewOrderDraftFormVue,
              eventHandlers: {
                validate: (isValid: boolean) => {
                  isOrderDraftValid.value.articleSelected = isValid;
                },
              },
            },
          ],
        },
        {
          title: "Deltaker og betaling",
          hidden: !isOrderDraftValid.value.articleSelected,
          valid: isOrderDraftValid.value.participantSelected,
          locked: !isOrderDraftValid.value.articleSelected,
          content: [
            {
              component: orderDraft.value.details?.isCompanyInternal
                ? NewOrderDraftCompanyVue
                : NewOrderDraftParticipantVue,
              eventHandlers: {
                validate: (isValid: boolean) => {
                  isOrderDraftValid.value.participantSelected = isValid;
                },
              },
            },
          ],
          actions: [
            {
              label: "Forrige steg",
              eventHandlers: {
                click: prevStep,
              },
              props: {
                outlined: true,
                class: "mr-4",
              },
            },
            {
              label: "Neste steg",
              iconRight: "mdi-arrow-right",
              props: {
                color: "primary",
                disabled: !isOrderDraftValid.value.participantSelected,
              },
              eventHandlers: {
                click: () => {
                  globalLoadingWrapper({ blocking: true }, async () => {
                    if (orderDraft.value.details?.isCompanyInternal) {
                      generateCompanyOrder(orderDraft.value);
                      return;
                    }
                    const singleOrders = orderDraft.value.userIds?.map((userId: number) => ({
                      ...orderDraft.value,
                      userIds: [userId],
                      invoiceRecipientUserId: userId,
                      customerUserId: userId,
                    }));
                    if (!singleOrders) {
                      return;
                    }
                    generateParticipantOrder(singleOrders);
                  });
                },
              },
            },
          ],
        },
        {
          title: "Sammendrag",
          hidden: !isOrderDraftValid.value.paymentSelected,
          locked: !isOrderDraftValid.value.paymentSelected,
          content: [
            {
              component: NewOrderOverviewVue,
            },
          ],
          actions: [
            {
              label: "Forrige steg",
              eventHandlers: {
                click: prevStep,
              },
              props: {
                outlined: true,
                class: "mr-4",
              },
            },
            {
              label: "Opprett ordre",
              iconRight: "mdi-send",
              props: {
                color: "primary",
              },
              eventHandlers: {
                click: () => {
                  globalLoadingWrapper({ blocking: true }, async () => {
                    if (!order.value) {
                      return;
                    }
                    let failedItems = await Promise.all(
                      order.value.map(async (currentOrder) => await createOrder(currentOrder))
                    );
                    failedItems = failedItems.filter((item) => item?.theirRef);
                    if (failedItems.length > 0) {
                      // @ts-ignore
                      order.value = failedItems;
                    } else {
                      router.push({
                        name: SingleCourseRouteNames.CourseEconomy,
                        params: { id: props.courseId.toString() },
                      });
                    }
                  });
                },
              },
            },
          ],
        },
      ].map((step, index) => ({ ...step, index: index + 1 }))
    );

    const nextStep = () => {
      if (currentStep.value === undefined) {
        currentStep.value = activeStep.value + 1;
        return;
      }
      currentStep.value += 1;
    };

    const prevStep = () => {
      if (currentStep.value === undefined) {
        currentStep.value = activeStep.value - 1;
        return;
      }
      currentStep.value -= 1;
    };

    const setStep = (step: NewOrderStep) => {
      if (step.locked || step.hidden) {
        return;
      }
      currentStep.value = step.index;
    };

    const isStepVisible = (step: NewOrderStep) => !step.locked && !step.hidden;

    const getStepProps = (step: NewOrderStep) => {
      const complete =
        (step.index && activeStep.value > step.index) || (activeStep.value === step.index && step.hidden);
      if (activeStep.value !== step.index) {
        return { complete, completeIcon: "mdi-check" };
      }
    };

    const filterEnabledItems = (items: { disabled?: boolean }[]) => items.filter((item) => !item.disabled);

    const currentStep = ref<number>();

    const activeStep = computed(() => currentStep.value ?? 1);

    return {
      applicationStatus,
      isOrderDraftValid,
      activeStep,
      currentStep,
      filterEnabledItems,
      getStepProps,
      isStepVisible,
      nextStep,
      prevStep,
      setStep,
      steps,
      order,
      updateOrder,
      orderDraft,
      updateOrderDraft,
    };
  },
});

const getOrderDraftTemplateInitialValues = (courseId: number) => ({
  courseId,
  invoiceRecipientUserId: 0,
  customerUserId: 0,
  userIds: [],
  articles: [],
  details: {
    isCompanyInternal: false,
    isCollectiveInvoice: true,
  },
});

interface NewOrderStep {
  index?: number;
  title: string;
  hidden?: boolean;
  locked?: boolean;
  complete?: boolean;
  content: {
    component: unknown;
    eventHandlers?: Record<string, unknown>;
  }[];
  props?: Record<string, unknown | unknown[]>;
}
