var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Bestillingsdetaljer")])]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.orderValues
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.displayOrder(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-application-edit-outline ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis / Rediger")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "deleteArticle"
                },
                on: {
                  "click": function click($event) {
                    return _vm.displayDeleteOrderModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-delete")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett ordre")])])];
      }
    }, {
      key: "item.orderLines",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.orderLines.length) + " ")];
      }
    }], null, true)
  }), _vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1200
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_vm.orderValues && _vm.customerPersons && _vm.customerOrganizations ? _c('NewOrderOverviewModal', {
    attrs: {
      "order": _vm.modalData.existingItem,
      "orders": _vm.orderValues,
      "index": _vm.modalData.additionalProperties.index,
      "customerPersons": _vm.customerPersons,
      "customerOrganizations": _vm.customerOrganizations
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "submit": _vm.editOrder
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.deleteModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    },
    on: {
      "close": function close($event) {
        _vm.deleteModalData.showModal = false;
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteModalData
    },
    on: {
      "close": function close($event) {
        _vm.deleteModalData.showModal = false;
      },
      "submit": _vm.deleteOrder
    }
  }, [_vm._v(" Er du sikker på at du vil fjerne ordren? ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }