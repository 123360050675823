var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": _vm.validate
    }
  }, [_c('v-row', {
    staticClass: "mx-1"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Fakturering: ")])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "rules": _vm.validateBoolean,
      "dense": "",
      "label": "Hvem skal du fakturere?",
      "row": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.draftValues.details.isCompanyInternal,
      callback: function callback($$v) {
        _vm.$set(_vm.draftValues.details, "isCompanyInternal", $$v);
      },
      expression: "draftValues.details.isCompanyInternal"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Bedrift",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Deltaker",
      "value": false
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Velg artikler: ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "data-cy": "articles",
      "label": "Artikler",
      "items": _vm.filteredArticleCostList,
      "loading": _vm.isLoadingArticles,
      "item-text": "name",
      "item-value": _vm.articleValue,
      "rules": _vm.validateArrayNotEmpty,
      "multiple": "",
      "clearable": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.draftValues.articles,
      callback: function callback($$v) {
        _vm.$set(_vm.draftValues, "articles", $$v);
      },
      expression: "draftValues.articles"
    }
  })], 1), _vm.draftValues.articles.length > 0 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    attrs: {
      "hide-default-footer": "",
      "items": _vm.draftValues.articles,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "item.amountOverride",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatCurrency(value) : _vm.coursePrice) + " ")];
      }
    }, {
      key: "item.overrideAmount",
      fn: function fn(props) {
        return [_c('v-text-field', {
          attrs: {
            "name": "Pris",
            "suffix": "kr",
            "reverse": "",
            "hide-details": "",
            "dense": ""
          },
          on: {
            "change": _vm.emitChanges
          },
          model: {
            value: props.item.amountOverride,
            callback: function callback($$v) {
              _vm.$set(props.item, "amountOverride", _vm._n($$v));
            },
            expression: "props.item.amountOverride"
          }
        })];
      }
    }], null, true)
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }