import { NewOrderDraftExtended } from "./../../../shared/interfaces/NewOrderDraftExtended";
import {
  ApiGetCourseParticipantKursAdminDto,
  ApiGetKursAdminCourseDto,
  ApiOrderTemplateDto,
} from "@/api/generated/Api";
import { PropType } from "@vue/composition-api";

export const newOrderProps = {
  courseId: {
    type: Number,
    required: true,
  },
  courseParticipantStudents: {
    type: Array as PropType<ApiGetCourseParticipantKursAdminDto[]>,
    required: true,
  },
  course: {
    type: Object as PropType<ApiGetKursAdminCourseDto>,
    required: true,
  },
  order: {
    type: Array as PropType<ApiOrderTemplateDto[]>,
    required: false,
  },
  orderDraft: {
    type: Object as PropType<NewOrderDraftExtended>,
    required: true,
  },
} as const;
