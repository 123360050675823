var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.contentReady ? _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateBack
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " - Økonomi ")];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function callback($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#order"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-playlist-check")]), _vm._v(" Ordre ")], 1), _c('v-tab', {
          attrs: {
            "href": "#refundorder"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-text")]), _vm._v(" Refusjoner ")], 1)], 1)];
      },
      proxy: true
    }], null, false, 809242544)
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "order"
    }
  }, [_c('CourseOrderTable', {
    attrs: {
      "courseParticipantStudents": _vm.courseParticipantStudents,
      "course": _vm.course,
      "orders": _vm.courseOrders
    },
    on: {
      "loadOrders": _vm.loadOrders
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "refundorder"
    }
  }, [_c('CourseOrderTable', {
    attrs: {
      "courseParticipantStudents": _vm.courseParticipantStudents,
      "course": _vm.course,
      "orders": _vm.courseRefundOrders,
      "isRefundTable": ""
    },
    on: {
      "loadOrders": _vm.loadOrders
    }
  })], 1)], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }